import React, { Suspense, useEffect, lazy, useState } from 'react';
import { Spin, Row, ConfigProvider, message, Skeleton } from 'antd';
import itIT from 'antd/es/locale/it_IT';
import { Switch, Route, Redirect } from "react-router-dom";
import { AdminTemplate } from "./components/template";
import { sendRequest } from 'components/utils/axios.config';
import { UserContext } from 'components/context/user-context';
import Lottie from 'react-lottie';
import animationData from 'img/logo.json';
import { empty } from 'components/utils/utils';

const Login = lazy(() => import('./views/login'));
const Home = lazy(() => import('./views/home'));
const ReteCommerciale = lazy(() => import('./views/rete-commerciale'));
const SaggiAssegnati = lazy(() => import('./views/saggi-assegnati'));
const QuestionariDiValutazione = lazy(() => import('./views/questionari-di-valutazione'));
const QuestionarioDiGradimento = lazy(() => import('./views/questionario-di-gradimento'));
const CorsiDiFormazione = lazy(() => import('./views/corsi-di-formazione'));
const AreaDocenti = lazy(() => import('./views/area-docenti'));
const AreaLead = lazy(() => import('./views/area-lead'));
const NotificheAreaCommerciale = lazy(() => import('./views/notifiche-area-commerciale'));
const StatisticheAreaCommerciale = lazy(() => import('./views/statistiche-area-commerciale'));
const Faq = lazy(() => import('./views/faq'));
const CategorieFaq = lazy(() => import('./views/categorie-faq'));
const Ticket = lazy(() => import('./views/ticket'));
const TipologieTicket = lazy(() => import('./views/tipologie-ticket'));
const Newsletter = lazy(() => import('./views/newsletter'));
const NewsletterInviate = lazy(() => import('./views/newsletter-inviate'));
const NewsletterStatistiche = lazy(() => import('./views/newsletter-statistiche'));
const NewsletterInviateLattes = lazy(() => import('./views/newsletter-inviate-lattes'));
const NewsletterStatisticheLattes = lazy(() => import('./views/newsletter-statistiche-lattes'));
const NewsletterCoda = lazy(() => import('./views/newsletter-coda'));
const EmailInviate = lazy(() => import('./views/email-inviate'));
const Liste = lazy(() => import('./views/liste'));
const AmbientedStudenti = lazy(() => import('./views/ambiented-studenti'));
const AmbientedDocenti = lazy(() => import('./views/ambiented-docenti'));
const QrCode = lazy(() => import('./views/qrcode'));

var first_render = true;

const DelayedSpinner = () => {
    const [showSpinner, setShowSpinner] = useState(false);
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => setShowSpinner(true), 600);

        return () => clearTimeout(timer);
    });

    return showSpinner && <div
        id="caricamento-globale">
        <Lottie options={defaultOptions}
            height={100}
            width={100}
        />
    </div>;
};

function Routes() {

    if (localStorage.getItem("logged") == undefined) {
        localStorage.setItem("logged", false);
    }

    var [activeRequests, setActiveRequests] = useState(0);
    var [user_info, setUserInfo] = useState({});

    const getUserInfo = async () => {
        let response = await sendRequest.get('/admin/info');
        setUserInfo(response);
    }

    if (first_render) {
        /**
         * impostazioni axios
         */
        // Add a request interceptor
        sendRequest.interceptors.request.use(function (config) {
            // Do something before request is sent
            setActiveRequests(prevActiveRequests => prevActiveRequests + 1);
            return config;
        }, function (error) {
            // Do something with request error
            setActiveRequests(prevActiveRequests => prevActiveRequests - 1);
            return Promise.reject(error);
        });
        // Add a response interceptor
        sendRequest.interceptors.response.use(function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            // console.log(response);
            setActiveRequests(prevActiveRequests => prevActiveRequests - 1);
            return response.data;
        }, function (error) {

            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            if (error.response.status === 403) {
                message.error('Non hai i permessi per accedere a questa risorsa');
                localStorage.setItem("logged", 'false');
            }
            if (error.response.status === 404) {
                message.error('404 Url not found');
            }
            if (error.response.status === 400) {
                message.error(error.response.data ? error.response.data : 'Errore durante la richiesta');
            }
            setActiveRequests(prevActiveRequests => prevActiveRequests - 1);
            return Promise.reject(error);
        });
        /**
         * fine impostazioni axios
         */

        if (localStorage.getItem("logged") === 'true') {
            getUserInfo();
        }

        first_render = false;
    }

    return (
        <div >
            <ConfigProvider locale={itIT}>
                <UserContext.Provider value={{ info: user_info, updateUserContext: setUserInfo }}>
                    {localStorage.getItem("logged") !== 'true' ?
                        <Suspense
                            fallback={
                                <Row type="flex" justify="space-around" align="middle" style={{ minHeight: "calc(100vh - 112px)" }}>
                                    <Spin />
                                </Row>
                            }
                        >
                            <Switch>
                                <Route exact path="/login">
                                    <Login />
                                </Route>
                                <Route path="/">
                                    <Redirect to="/login" />
                                </Route>
                            </Switch>
                        </Suspense>
                        :
                        empty(user_info) ? null : <AdminTemplate>
                            <Suspense fallback={<Skeleton active />}>
                                {user_info.email != "qrcode@lattes.app" ? <Switch>
                                    <Route exact path="/">
                                        <Home />
                                    </Route>
                                    <Route exact path="/rete-commerciale">
                                        <ReteCommerciale />
                                    </Route>
                                    <Route exact path="/saggi-assegnati">
                                        <SaggiAssegnati />
                                    </Route>
                                    <Route exact path="/questionari-di-valutazione">
                                        <QuestionariDiValutazione />
                                    </Route>
                                    <Route exact path="/questionario-di-gradimento">
                                        <QuestionarioDiGradimento />
                                    </Route>
                                    <Route exact path="/corsi-di-formazione">
                                        <CorsiDiFormazione />
                                    </Route>
                                    <Route exact path="/area-docenti">
                                        <AreaDocenti />
                                    </Route>
                                    <Route exact path="/area-lead">
                                        <AreaLead />
                                    </Route>
                                    <Route exact path="/notifiche-area-commerciale">
                                        <NotificheAreaCommerciale />
                                    </Route>
                                    <Route exact path="/statistiche-area-commerciale">
                                        <StatisticheAreaCommerciale />
                                    </Route>
                                    <Route exact path="/faq">
                                        <Faq />
                                    </Route>
                                    <Route exact path="/faq/categorie-faq">
                                        <CategorieFaq />
                                    </Route>
                                    <Route exact path="/ticket">
                                        <Ticket />
                                    </Route>
                                    <Route exact path="/ticket/tipologie-ticket">
                                        <TipologieTicket />
                                    </Route>
                                    <Route exact path="/newsletter">
                                        <Newsletter />
                                    </Route>
                                    <Route exact path="/newsletter/inviate-agenzie">
                                        <NewsletterInviate />
                                    </Route>
                                    <Route exact path="/newsletter/statistiche-agenzie">
                                        <NewsletterStatistiche />
                                    </Route>
                                    <Route exact path="/email-inviate">
                                        <EmailInviate />
                                    </Route>
                                    <Route exact path="/newsletter/inviate-lattes">
                                        <NewsletterInviateLattes />
                                    </Route>
                                    <Route exact path="/newsletter/statistiche-lattes">
                                        <NewsletterStatisticheLattes />
                                    </Route>
                                    <Route exact path="/newsletter/coda">
                                        <NewsletterCoda />
                                    </Route>
                                    <Route exact path="/liste">
                                        <Liste />
                                    </Route>
                                    <Route exact path="/ambiented/studenti">
                                        <AmbientedStudenti />
                                    </Route>
                                    <Route exact path="/ambiented/docenti">
                                        <AmbientedDocenti />
                                    </Route>

                                    <Route exact path="/qrcode">
                                        <QrCode />
                                    </Route>
                                </Switch> :
                                    <Switch>
                                        <Route exact path="/qrcode">
                                            <QrCode />
                                        </Route>
                                        <Route path="*">
                                            <Redirect to="/qrcode" />
                                        </Route>
                                    </Switch>
                                }
                            </Suspense>
                        </AdminTemplate>
                    }
                </UserContext.Provider>
            </ConfigProvider>
            {activeRequests > 0 && <DelayedSpinner />}
        </div>
    );
}


export default Routes;
