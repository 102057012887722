import { AppstoreOutlined, CloseCircleOutlined, LogoutOutlined, MailOutlined, MenuFoldOutlined, MenuUnfoldOutlined, QrcodeOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Col, Divider, Layout, Menu, message, Row } from 'antd';
import { UserContext } from 'components/context/user-context';
import { sendRequest } from 'components/utils/axios.config';
import approvazioneDocentiNC from "img/icons/approvazione-docenti-nc.svg";
import attivazioneSaggioNC from "img/icons/attivazione-saggio-nc.svg";
import catalogoLibriNC from "img/icons/catalogo-libri-nc.svg";
import listaNC from "img/icons/listaNC.svg";
import logoAmbiented from "img/icons/logo-ambiented.svg";
import materialePropagandaNC from "img/icons/materiale-propaganda-nc.svg";
import portfolioDocentiNC from "img/icons/portfolio-docenti-nc.svg";
import registrazioneDocentiNC from "img/icons/registrazione-docenti-nc.svg";
import statisticaAreaCommercialeNC from "img/icons/statisticaAreaCommercialeNC.svg";
import ticketIcon from "img/icons/ticket.svg";
import logoBG from "img/logo-bg.svg";
import logo from "img/logo-nav.png";
import React, { useContext, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import areaLead from "../../img/icons/area-lead-nc.svg";
import notificheCommercialiNC from "../../img/icons/notificheCommercialiNC.svg";

import "./adminTemplate.css";

const { SubMenu } = Menu;
const { Header, Sider, Content, Footer } = Layout;

function AdminTemplate(props) {


    const s_width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;

    const [collapsed, setCollaped] = useState((s_width < 768));

    let user = useContext(UserContext);

    let location = useLocation();

    let history = useHistory();

    var toggle = () => {
        setCollaped(!collapsed);
    }

    var mobileCollapse = () => {
        if (s_width < 768) setCollaped(true);
    }


    var logout = () => {

        sendRequest.get('/admin/logout').then(resp => {
            if (resp.result === 'ok') {
                localStorage.setItem("logged", 'false');
                user.updateUserContext({});
                history.push("/login");
            } else if (resp.result === 'ko') {
                message.error(resp.error);
            }
        });
    }


    return (
        <Layout>
            <img src={logoBG} id="logoBg" />
            <Sider
                trigger={null}
                collapsible
                collapsed={collapsed}
                collapsedWidth={s_width < 768 ? '0' : '80'}
                width="250"
                style={{ zIndex: 2 }}
                className="sider-menu"
            >
                <Link to="/" style={{
                    textAlign: 'center'
                }}>

                    <div className="logo" style={{ height: '64px', padding: '10px' }}>
                        <img src={logo} className="logo" />
                    </div>
                </Link>
                {s_width < 768 ? <div style={{ textAlign: 'right' }}>
                    <CloseCircleOutlined
                        className="close-menu-icon"
                        onClick={toggle}
                        style={{
                            fontSize: "20px",
                            padding: "15px",
                            color: 'white'
                        }} />
                </div> : ''}
                <Divider style={{ marginTop: 0 }} />
                <Menu theme="dark" mode="inline" selectedKeys={[location.pathname]}>
                    {user.info.email !== "qrcode@lattes.app" && <>
                        <Menu.Item key="/area-docenti">
                            <Link style={{ display: 'flex', alignItems: 'center' }} to="/area-docenti" onClick={mobileCollapse}>
                                <img src={portfolioDocentiNC} className={'icon-sider anticon anticon-unordered-list'} />
                                <span>Area Docenti</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="/area-lead">
                            <Link style={{ display: 'flex', alignItems: 'center' }} to="/area-lead" onClick={mobileCollapse}>
                                <img src={areaLead} className={'icon-sider anticon anticon-unordered-list'} />
                                <span>Area Lead</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="/rete-commerciale">
                            <Link style={{ display: 'flex', alignItems: 'center' }} to="/rete-commerciale" onClick={mobileCollapse}>
                                <img src={attivazioneSaggioNC} className={'icon-sider anticon anticon-unordered-list'} />
                                <span>Rete Commerciale</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="/corsi-di-formazione">
                            <Link style={{ display: 'flex', alignItems: 'center' }} to="/corsi-di-formazione" onClick={mobileCollapse}>
                                <img src={approvazioneDocentiNC} className={'icon-sider anticon anticon-unordered-list'} />
                                <span>Corsi di Formazione </span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="/questionari-di-valutazione">
                            <Link style={{ display: 'flex', alignItems: 'center' }} to="/questionari-di-valutazione" onClick={mobileCollapse}>
                                <img src={registrazioneDocentiNC} className={'icon-sider anticon anticon-unordered-list'} />
                                <span>Questionari di Valutazione</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="/questionario-di-gradimento">
                            <Link style={{ display: 'flex', alignItems: 'center' }} to="/questionario-di-gradimento" onClick={mobileCollapse}>
                                <img src={materialePropagandaNC} className={'icon-sider anticon anticon-unordered-list'} />
                                <span>Questionario di Gradimento</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="/notifiche-area-commerciale">
                            <Link style={{ display: 'flex', alignItems: 'center' }} to="/notifiche-area-commerciale" onClick={mobileCollapse}>
                                <img src={notificheCommercialiNC} className={'icon-sider anticon anticon-unordered-list'} />
                                <span>Notifiche Area Commerciale </span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="/saggi-assegnati">
                            <Link style={{ display: 'flex', alignItems: 'center' }} to="/saggi-assegnati" onClick={mobileCollapse}>
                                <img src={catalogoLibriNC} className={'icon-sider anticon anticon-unordered-list'} />
                                <span>Saggi Assegnati </span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="/statistiche-area-commerciale">
                            <Link style={{ display: 'flex', alignItems: 'center' }} to="/statistiche-area-commerciale" onClick={mobileCollapse}>
                                <img src={statisticaAreaCommercialeNC} className={'icon-sider anticon anticon-unordered-list'} />
                                <span style={{ fontSize: 13 }}>Statistiche Area Commerciale </span>
                            </Link>
                        </Menu.Item>


                        <SubMenu
                            key="sub-faq"
                            title={
                                <Link to="/faq" style={{ display: 'flex', width: '100%' }} onClick={mobileCollapse}>
                                    <QuestionCircleOutlined style={{ display: 'flex', alignItems: 'center', fontSize: 25 }} />
                                    <span>Faq</span>
                                </Link>
                            }
                        >
                            <Menu.Item key="/faq">
                                <Link to="/faq" onClick={mobileCollapse}>
                                    <span>Lista Faq</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="/faq/categorie-faq">
                                <Link to="/faq/categorie-faq" onClick={mobileCollapse}>
                                    <span>Categorie Faq</span>
                                </Link>
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu
                            key="sub-ticket"
                            title={
                                <Link to="/ticket" style={{ display: 'flex' }} onClick={mobileCollapse}>
                                    <img src={ticketIcon} className={'icon-sider anticon anticon-unordered-list'} />
                                    <span>Ticket</span>
                                </Link>
                            }
                        >
                            <Menu.Item key="/ticket">
                                <Link to="/ticket" onClick={mobileCollapse}>
                                    <span>Lista Ticket</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="/ticket/tipologie-ticket">
                                <Link to="/ticket/tipologie-ticket" onClick={mobileCollapse}>
                                    <span>Tipologie Ticket</span>
                                </Link>
                            </Menu.Item>
                        </SubMenu>
                        <Menu.Item key="/liste">
                            <Link style={{ display: 'flex', alignItems: 'center' }} to="/liste" onClick={mobileCollapse}>
                                <img src={listaNC} className={'icon-sider anticon anticon-unordered-list'} />
                                <span>Liste</span>
                            </Link>
                        </Menu.Item>
                        <SubMenu
                            key="sub-newsletter"
                            title={
                                <Link to="/newsletter" style={{ display: 'flex', width: '100%' }} onClick={mobileCollapse}>
                                    <MailOutlined style={{ display: 'flex', alignItems: 'center', fontSize: 25 }} />
                                    <span>Newsletter</span>
                                </Link>
                            }
                        >
                            <Menu.Item key="/newsletter">
                                <Link to="/newsletter" onClick={mobileCollapse}>
                                    <span>Lista Newsletter</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="/newsletter/coda">
                                <Link to="/newsletter/coda" onClick={mobileCollapse}>
                                    <span> Coda di invio </span></Link>
                            </Menu.Item>
                            <Menu.Item key="/newsletter/inviate-agenzie">
                                <Link to="/newsletter/inviate-agenzie" onClick={mobileCollapse}>
                                    <span>Inviate dalla rete commerciale</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="/newsletter/statistiche-agenzie">
                                <Link to="/newsletter/statistiche-agenzie" onClick={mobileCollapse}>
                                    <span>Statistiche invii rete commerciale</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="/newsletter/inviate-lattes">
                                <Link to="/newsletter/inviate-lattes" onClick={mobileCollapse}>
                                    <span>Inviate da Lattes</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="/newsletter/statistiche-lattes">
                                <Link to="/newsletter/statistiche-lattes" onClick={mobileCollapse}>
                                    <span>Statistiche invii Lattes</span>
                                </Link>
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu
                            key="sub-ambiented"
                            title={
                                <Link to="/ambiented/docenti" style={{ display: 'flex', width: '100%' }} onClick={mobileCollapse}>
                                    <img src={logoAmbiented} className={'icon-sider anticon anticon-unordered-list'} />
                                    <span>AmbienteD</span>
                                </Link>
                            }
                        >
                            <Menu.Item key="/ambiented/docenti">
                                <Link to="/ambiented/docenti" onClick={mobileCollapse}>
                                    <span>Docenti</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="/ambiented/studenti">
                                <Link to="/ambiented/studenti" onClick={mobileCollapse}>
                                    <span>Studenti</span>
                                </Link>
                            </Menu.Item>
                        </SubMenu></>}
                    <Menu.Item key="/qrcode">
                        <Link to="/qrcode" style={{ display: 'flex', alignItems: 'center' }} onClick={mobileCollapse}>

                            <QrcodeOutlined style={{ fontSize: 25 }} />
                            <span>Qr code</span>

                        </Link>
                    </Menu.Item>
                    {user.info.email !== "qrcode@lattes.app" && <Menu.Item key="/email-inviate">
                        <Link to="/email-inviate" style={{ display: 'flex', alignItems: 'center' }} onClick={mobileCollapse}>

                            <AppstoreOutlined style={{ fontSize: 25 }} />
                            <span>Email inviate</span>

                        </Link>
                    </Menu.Item>}
                </Menu>
            </Sider>
            <Layout >
                <Header>
                    <Row >
                        <Col xs={8} md={10}>
                            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                className: 'trigger',
                                onClick: toggle,
                                style: {
                                    textAlign: 'left',
                                    fontSize: "20px",
                                    padding: "0 15px"
                                }
                            })}
                        </Col>
                        <Col xs={8} md={0}>
                            <Link to="/"><img src={logo} className="logo" /></Link>
                        </Col>
                        <Col xs={4} md={10}>
                        </Col>
                        <Col xs={4} md={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>

                            <a onClick={logout} className="logout" style={{ textAlign: 'right', color: 'inherit' }}>
                                {!(s_width < 768) ? <span>LOGOUT</span> : ""}
                                <LogoutOutlined
                                    style={{
                                        fontSize: "20px",
                                        padding: "0 15px 0 5px",
                                        verticalAlign: 'sub'
                                    }} />
                            </a>
                        </Col>
                    </Row>



                </Header>
                <Content
                    style={{
                        margin: '15px 15px 0px',
                        minHeight: "calc(100vh - 148px)"
                    }}
                    className="container-content"
                    id="main-content"
                >
                    {props.children}
                </Content>
                <Footer style={{ textAlign: 'center', background: '#eee' }}>
                    S. LATTES &amp; C. EDITORI S.p.A.<br />
                    Via Confienza, 6 - 10121 Torino<br />
                    Codice Fiscale e Partita Iva: 04320600010<br />
                    Iscrizione Registro Imprese di Torino n. 04320600010

                </Footer>
                <Row justify="center" style={{ width: '100%', background: '#f7f7f7' }}>
                    <span style={{ padding: '10px 20px', fontSize: 11 }}>Engineering and design by <a href="https://www.sernicola-labs.com/" target="_blank">Sernicola Labs Srl</a></span>
                </Row>
            </Layout>
        </Layout>
    );
}


export default AdminTemplate;