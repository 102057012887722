import axios from 'axios';

export const sendRequest = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  withCredentials: true
});

export const sendRequestNoLoading = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  withCredentials: true
});